import React from 'react';
import classnames from 'classnames';
import { graphql, navigate } from 'gatsby';

import SignButton from './SignButton';
import BackButton from '../BackButton/BackButton';
import JumpToLinks from '../JumpToLinks/JumpToLinks';
import CountrySelect from '../FormControls/CountrySelect';

import { sanitizer, useCSSMotion } from '../../util';

import './SignIndividual.scss';

function JumpToLinksWrapper({ hashLinks, containerRef }) {
  const motionState = useCSSMotion(containerRef);
  return <JumpToLinks hashLinks={hashLinks} motionState={motionState} />;
}

class SignIndividual extends React.Component {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef(null);

    this.state = {
      isProcessing: false,
      error: null,
      fieldErrors: new Set(),
    };
  }

  hasFieldError(id) {
    return this.state.fieldErrors.has(id);
  }

  renderSignForm({ agreement, signature, github }) {
    const { isProcessing } = this.state;

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="field-group">
          <div className={classnames('field', { error: this.hasFieldError('full_name') })}>
            <label htmlFor="full_name">{signature.fullNameLabel + '*'}</label>
            <input type="text" id="full_name" />
          </div>
        </div>
        <div className="field-group">
          <div className={classnames('field', { error: this.hasFieldError('mailing_address') })}>
            <label htmlFor="mailing_address">{signature.mailingAddressLabel + '*'}</label>
            <input type="text" id="mailing_address" />
          </div>
          <div className={classnames('field', { error: this.hasFieldError('country_code') })}>
            <label htmlFor="country_code">{signature.countryLabel + '*'}</label>
            <div className="select-wrapper">
              <CountrySelect id="country_code" />
            </div>
          </div>
        </div>
        <SignButton
          clientId={github.clientId}
          redirectUri={github.redirectUri}
          scope="user:email"
          className={classnames('submit-button', { processing: isProcessing })}
          disabled={isProcessing}
          buttonText={isProcessing ? 'Processing...' : signature.submitText}
          validateForm={(callback) => {
            const fieldErrors = new Set();

            this.setState(
              {
                error: null,
                fieldErrors,
                isProcessing: true,
              },
              () => {
                ['full_name', 'mailing_address', 'country_code'].forEach((id) => {
                  const value = getFormValue(id);
                  if (isEmptyValue(value)) {
                    fieldErrors.add(id);
                  }
                });

                if (fieldErrors.size > 0) {
                  this.setState(
                    {
                      fieldErrors,
                      isProcessing: false,
                    },
                    () => {
                      callback(new Error('Please fill in all required fields.'));
                    },
                  );
                  return;
                }

                callback(null);
              },
            );
          }}
          onSuccess={({ code }) => {
            const formData = {
              cla_type: 'individual',
              cla_version: agreement.version,
              github_code: code,
              full_name: getFormValue('full_name'),
              mailing_address: getFormValue('mailing_address'),
              country_code: getFormValue('country_code'),
            };

            const options = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(formData),
            };

            fetch(process.env.WP_BACKEND_URL + process.env.LOCALE + '/wp-json/jfl/v1/cla/sign', options)
              .then((res) => {
                return res.json();
              })
              .then((res) => {
                if (isResponseError(res)) {
                  const responseFieldErrors = parseResponseFieldErrors(res);
                  this.setState(
                    {
                      error: parseResponseError(res),
                      fieldErrors: responseFieldErrors,
                      isProcessing: false,
                    },
                    () => {
                      if (responseFieldErrors.size > 0) {
                        focusFormField(responseFieldErrors.values().next().value);
                      }
                    },
                  );
                  return;
                }

                navigate(signature.completePage, {
                  replace: true,
                });
              })
              .catch((error) => {
                this.setState({
                  error: error,
                  isProcessing: false,
                });
              });
          }}
          onFailure={(error) => {
            this.setState({
              error: error,
              isProcessing: false,
            });
          }}
        />
        {this.renderFormError()}
      </form>
    );
  }

  renderFormError() {
    const { error } = this.state;

    if (error) {
      return (
        <div className="form-error">
          <div className="label">Error</div>
          <div className="message">{error.message || error}</div>
        </div>
      );
    }

    return null;
  }

  render() {
    const { data } = this.props;
    const { agreement, github, signature, title } = data;

    if (!data) {
      return null;
    }

    return (
      <section className="SignIndividualCLA" ref={this.containerRef}>
        <div className="breadcrumbs">
          {/* TODO: needs to be dynamic and translated */}
          <BackButton ref={this.containerRef} url="/cla-sign/" title="Back to Contributor License Agreements" />
        </div>

        <div className="container">
          <JumpToLinksWrapper
            containerRef={this.containerRef}
            hashLinks={[
              {
                id: 'agreement',
                jumpToLinkTitle: agreement.title,
              },
              {
                id: 'sign',
                jumpToLinkTitle: signature.title,
              },
            ]}
          />

          <div className="content">
            <h1 className="title">{title}</h1>

            <div id="agreement">
              <h4 className="sub-title">{agreement.title}</h4>
              <div dangerouslySetInnerHTML={{ __html: sanitizer(agreement.content) }} />
            </div>

            <div id="sign">
              <h4 className="sub-title">{signature.title}</h4>
              {this.renderSignForm({ agreement, github, signature })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function getFormValue(id) {
  const element = document.getElementById(id);
  if (element) {
    return element.value;
  }

  return null;
}

function isEmptyValue(value) {
  return !value || value.length === 0;
}

function isResponseError(res) {
  return res.code !== 'success';
}

function parseResponseError(res) {
  if (res.message) {
    return new Error(res.message);
  } else if (res.code) {
    return new Error(`An error occurred: ${res.code}`);
  } else {
    return new Error('An unknown error occurred.');
  }
}

function parseResponseFieldErrors(res) {
  if (res.code) {
    return new Set([res.code]);
  } else {
    return new Set();
  }
}

function focusFormField(id) {
  const element = document.getElementById(id);
  if (element) {
    return element.focus();
  }
}

export default SignIndividual;

export const fragments = graphql`
  fragment ClaSignIndividual on WpPage_Flexiblelayout_FlexibleChildren_ClaSignIndividual {
    agreement {
      content
      title
      version
    }
    github {
      clientId
      redirectUri
    }
    signature {
      completePage
      countryLabel
      emailLabel
      fieldGroupName
      fullNameLabel
      mailingAddressLabel
      submitText
      title
    }
    title
  }

  fragment ClaSignIndividual_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_ClaSignIndividual {
    agreement {
      content
      title
      version
    }
    github {
      clientId
      redirectUri
    }
    signature {
      completePage
      countryLabel
      emailLabel
      fieldGroupName
      fullNameLabel
      mailingAddressLabel
      submitText
      title
    }
    title
  }
`;
