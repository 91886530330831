import GitHubLogin from '../GitHubLogin';

class SignButton extends GitHubLogin {
  constructor(props) {
    super(props);

    this.onBtnClick = this.onBtnClick.bind(this);
  }

  onBtnClick() {
    const { validateForm } = this.props;

    // Intercept the button click handler, and perform
    // a form validation before handling the click.
    if (typeof validateForm === 'function') {
      validateForm((error) => {
        if (error) {
          this.props.onFailure(error);
        } else {
          super.onBtnClick();
        }
      });
    } else {
      super.onBtnClick();
    }
  }
}

export default SignButton;
