import React from 'react';

import countryCodes from '../../util/country-codes';

function CountrySelect(props) {
  const { placeholder, ...selectProps } = props;

  const options = [
    <option key={0} value="" disabled selected>
      {placeholder || 'Select a country...'}
    </option>,
  ].concat(
    countryCodes.map((countryCode, index) => {
      return (
        <option key={1 + index} value={countryCode.abbreviation}>
          {countryCode.country}
        </option>
      );
    }),
  );

  return <select {...selectProps}>{options}</select>;
}

export default CountrySelect;
